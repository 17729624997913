
.navbar {
    background-color: #1a75ce !important;
    background-image: url(../img/logobG.png);
    background-repeat: no-repeat;
    background-position: center;
}

.navbar ul.nav > li > a {
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: bold;
    letter-spacing: 0.08em;
    border-top: solid 5px transparent;
}

.navbar ul.nav > li > a:hover {
    border-top: solid 5px #38a7bb;
}

.navbar ul.nav > li.active > a,
.navbar ul.nav > li.open > a {
    text-decoration: none !important;
    border-top: solid 5px #20616d;
}

@media (max-width: 768px) {
    .navbar ul.nav > li.active > a,
    .navbar ul.nav > li.open > a {
        border-top-color: transparent;
    }

    .navbar ul.nav > li > a:hover {
        border-top-color: transparent;
    }
}

.navbar.navbar-light ul.nav > li.active > a {
    border-top: solid 5px #20616d;
    background: #fff !important;
    color: #555555 !important;
}

.navbar.navbar-light ul.nav > li.active > a:hover {
    border-top: solid 5px #20616d;
}

.navbar.navbar-light ul.nav > li > a:hover,
.navbar.navbar-light ul.nav > li.open > a:hover,
.navbar.navbar-light ul.nav > li > a:focus,
.navbar.navbar-light ul.nav > li.open > a:focus {
    border-top: solid 5px #38a7bb;
    background: #fff !important;
    color: #555555 !important;
}

.navbar ul.dropdown-menu {
    margin: 0;
    padding: 0;
}

.navbar ul.dropdown-menu li {
    list-style-type: none;
    border-bottom: solid 1px #eeeeee;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    padding: 4px 0;
}

.navbar ul.dropdown-menu li a {
    position: relative;
    color: #999999;
    font-size: 12px;
    display: block;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    left: 0;
}

.navbar ul.dropdown-menu li a:hover {
    color: #38a7bb;
    text-decoration: none;
    background: none;
    left: 2px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

@media (max-width: 767px) {
    .navbar ul.dropdown-menu li a:hover {
        left: 0;
    }
}

.navbar .btn-default,
.navbar .btn-default.navbar-toggle {
    color: #999999;
    background-color: #fff;
    margin-left: 7px;
    margin-right: 0;
}

.navbar .btn-default:hover,
.navbar .btn-default.navbar-toggle:hover,
.navbar .btn-default:focus,
.navbar .btn-default.navbar-toggle:focus {
    background-color: #fff;
    border-color: #38a7bb;
    color: #38a7bb;
}
