.contexto {
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.titulo {
    background-color: #01163d;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 22px;
    font-weight: 600;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 0;
}

.tituloNFe .titulo {
    text-transform: none;
}

.subtitulo {
    color: #036;
    padding: 15px;
    margin-bottom: 0;
    background-color: #f7f7f7;
    text-align: left;
}

.dadosEmitente {
    background-color: #e8e8e8;
    padding: 30px;
    color: #036;
    border-bottom: 1px dashed #5f97fa;
    /*line-height: 1.4;*/
}

.dadosNFe, .valorTotal, .listaItens, .listaNFes, .adicionarItem {
    background-color: #e8e8e8;
    padding: 30px;
    color: #036;
    border-bottom: 1px dashed #5f97fa;
}

.listaItens, .listaNFes, .adicionarItem {
    margin-top: 0;
}

section.conteudo {
    min-height: 15em;
}