/* general styles */
a,
button {
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

a i.fa,
button i.fa {
    margin: 0 5px;
}

section,
div.section {
    margin-bottom: 20px;
}

.heading {
    margin-bottom: 40px;
}

.heading h1,
.heading h2,
.heading h3,
.heading h4,
.heading h5 {
    display: inline-block;
    border-bottom: solid 5px #38a7bb;
    line-height: 1.1;
    margin-bottom: 0;
    padding-bottom: 10px;
    vertical-align: middle;
    text-transform: uppercase;
    letter-spacing: 0.06em;
}

.heading h1 i.fa,
.heading h2 i.fa,
.heading h3 i.fa,
.heading h4 i.fa,
.heading h5 i.fa {
    display: inline-block;
    background: #38a7bb;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    font-size: 12px;
    line-height: 30px;
    border-radius: 15px;
}

@media (max-width: 991px) {
    #intro .item h1 {
        font-size: 40px;
    }
}

@media (max-width: 767px) {
    #intro .item h1 {
        font-size: 25px;
    }
}

#intro .item h3 {
    color: #fff;
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    #intro .item h3 {
        font-size: 15px;
        margin-bottom: 20px;
    }
}

#intro .item .btn {
    text-transform: none;
}

@media (max-width: 991px) {
    #intro .item .btn {
        font-size: 14px;
    }
}

@media (max-width: 991px) {
    #intro .item .carousel-caption {
        left: 10%;
        right: 10%;
    }
}

[data-animate] {
    opacity: 0;
    filter: alpha(opacity=0);
}

#style-switch-button {
    position: fixed;
    top: 100px;
    left: 0px;
    border-radius: 0;
}

#style-switch {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 300px;
    padding: 20px;
    position: fixed;
    top: 140px;
    left: 0;
    background: #fff;
    border: solid 1px #eeeeee;
}

@media (max-width: 991px) {
    #style-switch-button {
        display: none;
    }

    #style-switch {
        display: none;
    }
}

/* Original Boostrap template overwrite */
/* breadcrumbs */
.breadcrumb {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.08em;
}

/* nav */
.nav > li > a {
    padding: 10px 15px;
}

.nav > li > a:hover,
.nav > li > a:focus {
    background-color: #1a75ce;
    color: #fff;
}

.nav > li.disabled > a {
    color: #999999;
}

.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
    color: #999999;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    background-color: #eeeeee;
    border-color: #38a7bb;
}

.nav-tabs {
    border-bottom: 1px solid #38a7bb;
}

.nav-tabs > li {
    float: left;
    margin-bottom: -1px;
}

.nav-tabs > li > a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 0 0 0 0;
}

.nav-tabs > li > a:hover {
    border-color: #eeeeee #eeeeee #38a7bb;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: #555555;
    background-color: #ffffff;
    border: 1px solid #38a7bb;
    border-bottom-color: transparent;
    cursor: default;
}

.nav-tabs.nav-justified {
    width: 100%;
    border-bottom: solid 1px #38a7bb;
    border-bottom: 0;
}

.nav-tabs.nav-justified > li {
    float: none;
}

.nav-tabs.nav-justified > li > a {
    text-align: center;
    /*margin-bottom: 5px;*/
}

.nav-tabs.nav-justified > .dropdown .dropdown-menu {
    top: auto;
    left: auto;
}

.nav-tabs.nav-justified > li > a {
    margin-right: 0;
    border-radius: 0;
}

.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
    border: 1px solid #38a7bb;
}

@media (min-width: 768px) {
    .nav-tabs.nav-justified > li > a {
        border-bottom: 1px solid #38a7bb;
        border-radius: 0 0 0 0;
    }

    .nav-tabs.nav-justified > .active > a,
    .nav-tabs.nav-justified > .active > a:hover,
    .nav-tabs.nav-justified > .active > a:focus {
        border-bottom-color: #ffffff;
    }
}

.nav-pills > li, .nav-ferramentas > li {
    float: left;
}

.nav-ferramentas > li {
    margin-right: 10px;
}

.nav-ferramentas {
    margin-top: 20px;
    margin-bottom: 15px;
}

.help-block-inutilizacoes {
    display: block;
    margin-top: 30px;
}

.nav-pills > li > a {
     border-radius: 0;
     color: #ffffff;
     background-color: #024e99;
     padding: 25px;
     margin: 5px;
 }

.nav-pills > li > button,
.nav-pills > li > button[disabled]:hover,
.nav-pills > li > button:hover,
.nav-pills > li > button:focus {
    border-radius: 0;
    color: #ffffff;
    background-color: #024e99;
    padding: 21px;
    margin: 5px;
    font-size: 18px;
}

.nav-pills > li > button[disabled]:hover,
.nav-pills > li > button[disabled] {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 16px;
}

.nav-pills > li > button:hover,
.nav-pills > li > button:focus {
    background-color: #1a75ce;
}

.nav-pills > li + li {
    margin-left: 2px;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    color: #ffffff;
    background-color: #1a75ce !important;

}

.nav-stacked > li {
    float: none;
}

.nav-stacked > li + li {
    margin-top: 2px;
    margin-left: 0;
}

.nav-justified {
    width: 100%;
    border-bottom: solid 1px #38a7bb;
}

.nav-justified > li {
    float: none;
}

.nav-justified > li > a {
    text-align: center;
    /*margin-bottom: 5px;*/
}

.nav-justified > .dropdown .dropdown-menu {
    top: auto;
    left: auto;
}

.nav-tabs-justified {
    border-bottom: 0;
}

.nav-tabs-justified > li > a {
    margin-right: 0;
    border-radius: 0;
}

.nav-tabs-justified > .active > a,
.nav-tabs-justified > .active > a:hover,
.nav-tabs-justified > .active > a:focus {
    border: 1px solid #38a7bb;
}

@media (min-width: 768px) {
    .nav-tabs-justified > li > a {
        border-bottom: 1px solid #38a7bb;
        border-radius: 0 0 0 0;
    }

    .nav-tabs-justified > .active > a,
    .nav-tabs-justified > .active > a:hover,
    .nav-tabs-justified > .active > a:focus {
        border-bottom-color: #ffffff;
    }
}

.tab-content {
    padding: 15px;
    border: solid 1px #ddd;
    border-top: none;
}

/* scaffolding */
body {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1;
    color: #FFFFFF;
}

a {
    color: #5f97fa;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #188afe;
    text-decoration: underline;
}

a:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.img-rounded {
    border-radius: 0;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eeeeee;
}

/* breadcrumbs */
.breadcrumb {
    padding: 20px 0;
    margin-bottom: 20px;
    background-color: transparent;
    border-radius: 0;
    text-align: right;
}

.breadcrumb > li + li:before {
    content: ">\00a0";
    color: #555555;
}

.breadcrumb > .active {
    color: #999999;
}

@media (max-width: 991px) {
    .breadcrumb {
        padding: 20px 0;
        text-align: center;
    }
}

/* dropdowns */
.dropdown-menu {
    z-index: 1000;
    font-size: 14px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.dropdown-menu.pull-right {
    right: 0;
    left: auto;
}

.dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.dropdown-menu > li > a {
    padding: 5px 20px;
    line-height: 1.42857143;
    color: #333333;
    white-space: nowrap;
}

/* pager*/
.pager {
    margin: 20px 0;
    border-top: solid 1px #eeeeee;
    padding-top: 20px;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
}

.pager li {
    display: inline;
}

.pager li > a,
.pager li > span {
    background-color: #ffffff;
    border: 1px solid #38a7bb;
    border-radius: 0;
}

.pager li > a:hover,
.pager li > a:focus {
    text-decoration: none;
    color: #fff;
    background-color: #38a7bb;
}

.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
    color: #999999;
    background-color: #ffffff;
    border-color: #ddd;
}

/* pagination */
.pagination {
    margin: 20px 0;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    border-radius: 0;
}

.pagination > li > a,
.pagination > li > span {
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #1873cb;
    background-color: #ffffff;
    border: 1px solid #dddddd;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    color: #1873cb;
    background-color: #a7dbe5;
    border-color: #dddddd;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    z-index: 2;
    color: #ffffff;
    background-color: #1873cb;
    border-color: #1873cb;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
    color: #999999;
    background-color: #ffffff;
    border-color: #dddddd;
}

/* responsive utilities */
@media (max-width: 767px) {
    .text-center-xs {
        text-align: center !important;
    }

    .text-center-xs img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .text-center-sm {
        text-align: center !important;
    }

    .text-center-sm img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

/* type */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: 900;
    line-height: 1.6;
    color: #fff;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-top: 20px;
    margin-bottom: 20px;
}

p {
    margin: 0 0 10px;
}

.lead {
    margin-bottom: 20px;
    font-size: 18px;
}

@media (min-width: 768px) {
    .lead {
        font-size: 21px;
    }
}

.text-small {
    font-size: 12px;
}

.text-large {
    font-size: 18px;
}

.text-italic {
    font-style: italic;
}

.text-primary {
    color: #38a7bb;
}

a.text-primary:hover {
    color: #2c8494;
}

.bg-primary {
    color: #fff;
    background-color: #38a7bb;
}

a.bg-primary:hover {
    background-color: #2c8494;
}

abbr[title],
abbr[data-original-title] {
    border-bottom: 1px dotted #999999;
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 14px;
    border-left: 5px solid #38a7bb;
}

blockquote footer,
blockquote small,
blockquote .small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #999999;
}

blockquote footer:before,
blockquote small:before,
blockquote .small:before {
    content: '\2014 \00A0';
}

.blockquote-reverse,
blockquote.pull-right {
    border-right: 5px solid #38a7bb;
}

address {
    margin-bottom: 20px;
    line-height: 1.42857143;
}

.progress {
    overflow: hidden;
    height: 20px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

a.badge:hover,
a.badge:focus {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
}

a.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
    color: #38a7bb;
    background-color: #ffffff;
}

.nav-pills > li > a > .badge {
    margin-left: 3px;
}

.progress-bar-primary {
    background-color: #38a7bb;
}

.progress-striped .progress-bar-primary {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
