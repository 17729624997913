table tr.nodata td {
    text-align: center;
    padding: 2em;
}

.line-before-table {
    background-color: #f7f7f7;;
    height: 1px;
}

.coluna-com-descricao-chave .dialogoUsers{
  color:  #036
}

.coluna-acoes .ic {
    margin-right: 0;
}

.coluna-acoes .btn {
    padding-left: 0;
    padding-right: 0;
}

.protocolo {
    margin-bottom: 8px;
}

.coluna-nfe {
    width: 100px;
    min-width: 60px;
}

.coluna-conta {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 15em;
}

.quebra-linha {
    overflow-wrap: break-word;
    min-width: 5em;
    max-width: 10em;
}
