#footer {
    color: #fff;
    padding: 65px 0 15px;
    background: #1a75ce url(../img/bgFooter.png) no-repeat;
}

#footer h1,
#footer h2,
#footer h3,
#footer h4,
#footer h5,
#footer h6 {
    color: #eeeeee;
}

#footer h4 {
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.08em;
}

#footer ul {
    padding-left: 0;
    list-style: none;
}

#footer ul a {
    color: #999999;
}

#footer ul a:hover {
    color: #38a7bb;
    text-decoration: none;
}

#copyright {
    color: #ccc;
    font-size: 12px;
    line-height: 28px;
    padding: 15px 0;
}

#copyright p {
    margin: 0;
}

@media (max-width: 991px) {
    #copyright p {
        float: none !important;
        text-align: center;
        margin-bottom: 10px;
    }
}