#abasDetalhesEmitente .tab-content {
    padding: 0;
    border: 0;
}

#abasDetalhesEmitente .nav-tabs > li.active > a,
#abasDetalhesEmitente .nav-tabs > li.active > a:focus,
#abasDetalhesEmitente .nav-tabs > li.active > a:hover {
    border: 0;
    border-bottom: 1px solid #ffffff;
}

#abasDetalhesEmitente .nav-tabs {
    border-bottom: 1px solid #ffffff;
}

#abasDetalhesEmitente .nav-tabs > li.active > a,
#abasDetalhesEmitente .nav-tabs > li.active > a:focus {
    color: #036;
}