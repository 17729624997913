.panel {
    margin-bottom: 20px;
    background-color: #ffffff;
    border: 1px solid transparent;
    border-radius: 0;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
}

.panel-heading {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    padding: 10px 10px;
}

.panel-title {
    line-height: 30px;
}

.panel-group {
    margin-bottom: 20px;
}

.panel-group .panel {
    margin-bottom: 0;
    border-radius: 0;
    overflow: hidden;
}

.panel-group .panel + .panel {
    margin-top: 5px;
}

.panel-group.accordion .panel {
    border-color: #ccc;
}

.panel-primary {
    border-color: #38a7bb;
}

.panel-primary > .panel-heading {
    color: #ffffff;
    background-color: #38a7bb;
    border-color: #38a7bb;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #38a7bb;
}

.panel-primary > .panel-heading .badge {
    color: #38a7bb;
    background-color: #ffffff;
}

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #38a7bb;
}

.panel-primary .panel-title {
    font-weight: 300;
}

.panel-primary .panel-title a:hover {
    color: #fff;
    text-decoration: none;
}

.itens-multiplos .panel {
    overflow: visible;
}

.itens-multiplos .panel-title {
    cursor: pointer;
}