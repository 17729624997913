/* your styles go here */

input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 1px;
  padding: 5px;
  margin-left: 0px;
  margin-bottom: 5px;
}

.logoTopo {
  float: right;
}

.rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.aposLista {
  margin-top: 25px;
}

.instrucaoUsuarioAntesCampo {
  margin-bottom: 38px;
}

.instrucaoUsuario {
  margin-bottom: 28px;
}

.instrucaoUsuarioAntesCampo,
.instrucaoUsuario {
  line-height: 20px;
}

@media (max-width: 768px) {
  .logoTopo {
    display: none;
  }
}

.dialogo {
  padding: 0px !important;
}

.data {
  color: #5b90ee;
  font-size: 24px;
}

.alerta {
  color: #000000;
  font-size: 14px;
}

.btsDialogo {
  padding: 0px !important;
}

.dialogoUsers {
  color: #5b90ee;
  font-size: 15px;
}

.bgDialogo {
  background-image: url(../img/bgDialogo.png);
  background-repeat: no-repeat;
  max-width: 1650px;
  width: 100%;
  height: 300px;
  padding-left: 35px;
  margin-bottom: 40px;
}

.funcionalidades {
  margin-top: -25px;
}

.erro {
  background-color: #ffffff;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 22px;
  font-weight: 600;
  color: #fa0232;
  text-transform: uppercase;
  margin-bottom: 0px;
  padding-left: 35px;
}

.setaTitulo {
  padding-top: 30px;
  background-image: url(../img/bgTitutlo.png);
  background-repeat: no-repeat;
  background-position: left;
  margin-left: 30px !important;
  padding-left: 30px !important;
}

.subtitulo1 {
  color: #5f97fa;
  padding: 5px;
  margin-bottom: 20px;
  border-bottom: 2px;
  border-bottom-style: solid;
}

.subtitulo2 {
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 0px;
  margin-bottom: -10px;
  background-color: #024e99;
  width: 95%;
  margin-left: 15px;
  float: left;
  margin-top: 20px;
}

.subtitulo3 {
  color: #5f97fa;
  margin-bottom: 10px;
  text-align: left;
}

.subtitulo4 {
  color: #000000;
  margin-bottom: 10px;
  text-align: left;
}

.plus {
  font-size: 24px;
}

.opLogin {
  padding-top: 15px;
  margin-left: -10px;
}

.form-horizontal {
  padding: 7px;
  color: #2568e0;
}

*::after,
*::before {
  box-sizing: border-box;
}

*::after,
*::before {
  box-sizing: border-box;
}

.logoEmitente {
  margin-bottom: 25px;
}

.Totais {
  background-color: #e8e8e8;
  padding: 30px;
  color: #036;
  width: 95%;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  border-bottom-color: #5f97fa;
  margin-top: 0px;
  text-align: right;
}

.totalIten {
  font-size: 24px !important;
  color: #3678ef;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: right;
}

.totalImpostos {
  font-size: 24px !important;
  color: #3678ef;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: right;
}

.totalGeral {
  font-size: 24px !important;
  color: #3678ef;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: right;
}

.total {
  font-size: 24px !important;
  color: #3678ef;
  padding-bottom: 20px;
  padding-top: 30px;
  padding-right: 45px !important;
  text-align: right;
  background-color: #f7f7f7;
  width: 95%;
  margin-left: 15px;
  margin-bottom: 20px;
}

.cadastroUsuarios {
  background-color: #e8e8e8;
  padding: 30px;
  color: #036;
  width: 95%;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  border-bottom-color: #5f97fa;
  margin-top: 0px;
}

.dadosDestinatario {
  background-color: #e8e8e8;
  padding: 30px;
  color: #036;
  width: 95%;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  border-bottom-color: #5f97fa;
  margin-top: 0px;
}

.avancar {
  text-align: center;
  font-size: 18px;
  height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.filtrar {
  text-align: center;
  font-size: 18px;
  height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  flex-direction: row-reverse;
  -webkit-align-items: flex-end;
  align-items: right;
  -webkit-justify-content: flex-end;
  justify-content: right;
  margin-top: 4px;
}

.filtrar .btn {
  margin-left: 8px;
}

.ic {
  margin-top: -3px;
  float: left;
  margin-right: 10px;
}

.numero {
  font-size: 18px;
  margin-right: 50px;
}

.serie {
  font-size: 18px;
  margin-right: 50px;
}

.data {
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 50px;
}

.form-check {
  margin-bottom: 15px;
}

.spLeft {
  margin-left: 15px;
  margin-bottom: 35px;
}

.popover-title {
  background-color: #ffffff;
  color: #5f97fa;
}

.mensagem {
  background-color: #ffffff;
  color: #000000;
}

.consultaForm {
  background-color: #e8e8e8;
  padding: 30px;
  color: #036;
  width: 95%;
  float: left;
  margin-left: 15px;
}

.legenda {
  background-color: #e8e8e8;
  padding: 30px;
  color: #036;
  width: 95%;
  float: left;
  margin-left: 15px;
}

.nge {
  color: #959596;
}

.epr {
  color: #ffae02;
}

.aut {
  color: #44ae03;
}

.den {
  color: #b59e01;
}

.rej {
  color: #0157cd;
}

.can {
  color: #f30017;
}

.int {
  color: #cd4901;
}

.consultar {
  text-align: center;
  font-size: 18px;
  height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}

.black-text {
  color: #000000;
}

.lista-nao-ordenada ul li {
  margin-bottom: 7px;
}

.lista-sem-marcador {
  list-style-type: none;
  padding-left: 4px;
}

.inline-block {
  display: inline-block;
}

.inline-block button {
  color: #808080;
  margin-bottom: 3px;
}

.pergunta-resposta {
  padding-bottom: 4px;
}

.pergunta-resposta:focus {
  outline: none;
}

.pergunta-resposta > div {
  margin-top: 1em;
}

.pergunta-resposta ol > li > ol {
  list-style-type: lower-latin;
}

.pergunta-resposta ol > li > ol > li > ol {
  list-style-type: lower-roman;
}

.pergunta-resposta ol > li > ol > li > ol > li > ol {
  list-style-type: decimal;
}

.modal-tabela-comunicacoes {
  width: 92%;
}

.tabela-comunicacoes {
  padding: 0 10px 5px 10px;
}

.tabela-protocolo {
  margin-bottom: 40px;
}

.subtitulo-antes-tabela {
  margin-bottom: 0;
}

.subtitulo-consulta-nfe {
  margin-top: 20px;
}

.detalhes-cadastros {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 15em;
  color: #5b90ee;
  font-size: 15px;
}

.labelForm-detalhes-cadastros {
  text-align: left;
  width: 250px;
  padding-bottom: 5px;
  padding-right: 1em;
}

button svg,
a svg {
  margin-right: 9px;
}

.menu-button svg {
  margin-right: 2px;
}

.menu-ajuda-button svg {
  margin-left: 2px;
}
