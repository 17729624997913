/* buttons  */
.input-group .btn {
    font-size: 14px;
}

.btn-lg {
    padding: 10px 16px;
    font-size: 14px;
    line-height: 1.33;
    border-radius: 0;
}

.btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 0;
}

.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 0;
}

.contexto .btn {
    background-color: #024e99;
    border-radius: 0 !important;
    color: #fff !important;
}

.contexto .btn {
    -moz-user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857;
    margin-bottom: 0;
    padding: 6px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}

.contexto .btn.active, .contexto .btn:active {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.125) inset;
    outline: 0 none;
    background: #01163d none;
    color: #FFF;
}

.nav-topo {
    background: #01163D url(../img/bgDialogo.png) no-repeat;
    width: 100%;
    margin-bottom: 40px;
    padding: .75em 0;
}

.nav-topo .btn,
.nav-topo .btn.active {
    font-weight: 500;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    letter-spacing: 0.08em;
    font-size: 13px;
    line-height: 1.42857143;
    border-radius: 0;
    background: none;
    color: #bfbfbf;
    border: solid 2px transparent;
    margin-right: 5px;
    text-decoration: none;
}

.nav-topo .btn.active {
    color: white;
    border-bottom: solid 1px white;
    padding-bottom: 7px;
}

.nav-topo .btn:hover,
.nav-topo .btn.active:hover,
.nav-topo .btn:focus,
.nav-topo .btn.active:focus,
.nav-topo .btn:active,
.nav-topo .btn.active:active {
    border-bottom: solid 2px #bfbfbf;
    padding-bottom: 6px;
}

.btnReal svg{
    margin-right: 1px;
}

.nav-topo .btnReal {
    background-color: #024e99;
    color: #fff !important;
}

.nav-topo .btn.btnReal:hover {
    background-color: #1a75ce;
}

.nav-topo .btn.btnReal:active,
.nav-topo .btn.btnReal:focus,
.nav-topo .btn.btnReal:hover {
    background-color: #1a75ce;
}

.contexto .btn { margin-right: 8px; padding: 6px 10px; }

.btnItem {
    width: 201px
}

.btnProdutoRastreado {
    text-align: left;
}

.btnFerramentas {
    width: 100px
}

.btnImportacao {
    color: #333;
}

.btnImportacao > a, .btnImportacao > a:hover, .btnImportacao > a:focus {
    color: #333;
    text-decoration: none;
}

.btnLinkConsulta {
    color: #188afe;
    margin: 5px 15px 5px 5px;
    padding: 0 0 0 0;
    font-size: 12px;
    font-weight: normal;
    float: right;
}

.menuFerramentas .btn-group .btn.active {
    font-weight: bold;
}

.btnPerguntaResposta, .btnPerguntaResposta:hover {
    color: #5f97fa;
    text-decoration: none;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1;
    border-width: 0 0 0 0;
    padding: 0 0 0 0;
}

.btnPerguntaResposta:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}