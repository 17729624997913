.label {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.08em;
}

.labelForm {
    text-align: left;
    width: 250px;
}

form .labelForm {
    width: auto;
}

label {
    font-weight: bold;
}

.react-select__control {
    font-size: 14px;
}

.form-control, .Select-control {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
}

.Select-control {
    margin-bottom: 5px;
}

.Select-control, .Select-input {
    height: 32px;
}

.react-toggle + span {
    margin-left: 0.5em;
    vertical-align: text-top;
    display: inline-block;
    margin-top: -0.20em;
}

.form-control, .Select-control, .Select-option, .VirtualizedSelectOption {
    color: black;
    font-size: 14px;
}

.form-control, .Select--single > .Select-control .Select-value, .VirtualizedSelectOption {
    padding-left: 12px;
    padding-right: 12px;
    line-height: 32px;
}

.form-control:focus, .Select.is-focused .Select-control {
    border-color: #38a7bb;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(56, 167, 187, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(56, 167, 187, 0.6);
}

.has-feedback .Select ~ .form-control-feedback {
    right: 18px;
}

.form-group {
    margin-bottom: 0;
}

.help-block {
    color: #999999;
    font-size: 65%;
    padding-left: .3em;
    margin-bottom: 8px;
}

.help-block .faint {
    display: inline-block;
    z-index: -1000;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 0 10px 3px white;
    min-height: 2em;
}

.controle-booleano {
    padding: 6px 0;
}

.p-aviso {
    margin-bottom: 20px;
}

.filtro .form-group {
    margin-bottom: 10px;
}

.filtro .row .col-sm-1, .filtro .row .col-sm-2, .filtro .row .col-sm-3, .filtro .row .col-sm-4, .filtro .row .col-sm-6 {
    padding-right: 5px;
    padding-left: 5px;
    height: 60px;
}

.filtro label {
    margin-bottom: 2px;
}

.modal-form .modal-body div {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.modal-form .modal-header div, .modal-form .modal-body div h4 {
    font-weight: 500;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.modal-form .labelForm {
    width: auto;
}

.campo-longo-mensagem div label {
    margin-bottom: 0;
    width: 222px;
}

.form-group .controle-booleano {
    padding-bottom: 0;
    padding-top: 2px;
}

.opcao-booleano-com-sim .form-group .help-block {
    margin-top: 0;
    margin-bottom: 0;
    height: 15px;
}

.gtin-possui {
    padding-right: 10px;
}

@media (min-width: 992px) {
    .campos-ean {
        width: 24.3%;
    }

    .campo-gtin {
        width: 27%;
    }

    .campo-ex-tipi {
        width: 14%;
    }

    .campo-cfop {
        width: 52.5%;
    }
}
