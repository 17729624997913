.novos-campos-enter {
  opacity: 0.01;
}

.novos-campos-enter.novos-campos-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.novos-campos-exit {
  opacity: 1;
}

.novos-campos-exit.novos-campos-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}